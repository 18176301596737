// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 2rem 1rem;
    background-color: #100c1c;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  @media (max-width: 768px) {
    .features-grid {
      grid-template-columns: 1fr;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,kBAAkB;IAClB,yBAAyB;IACzB,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE;MACE,0BAA0B;IAC5B;EACF","sourcesContent":[".features-grid {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    gap: 2rem;\n    padding: 2rem 1rem;\n    background-color: #100c1c;\n    max-width: 1400px;\n    margin: 0 auto;\n  }\n  \n  @media (max-width: 768px) {\n    .features-grid {\n      grid-template-columns: 1fr;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
